import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { BiLogoFacebook } from "react-icons/bi";
import { AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "./App.css";
import { useNavigate } from "react-router-dom";

// import required modules
import { Navigation } from "swiper/modules";

const Home = () => {
  let country = [
    {
      img: "/australia.png",
      name: "Australia",
    },
    {
      img: "/united_kingdom.png",
      name: "U K",
    },
    {
      img: "/united_states.png",
      name: "USA",
    },
    {
      img: "/canada.png",
      name: "Canada",
    },
    {
      img: "./nz.png",
      name: "New Zealand",
    },
  ];
  const servicesData = [
    {
      img: "/Study-Abroad.jpg",
      text: "Study Abroad",
    },
    {
      img: "/IELTS.jpg",
      text: "IELTS",
    },
    {
      img: "/PTE.jpg",
      text: "PTE",
    },
    {
      img: "/se.jpg",
      text: "Spoken English",
    },
    {
      img: "/workvis.jpg",
      text: "Work Visa",
    },
    {
      img: "/tourist.jpg",
      text: "Tourist Visa",
    },
  ];

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact");
  };
  return (
    <>
      <div>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          navigation={true}
          pagination={{ clickable: true }}
          modules={[Navigation]}
          style={{
            height: 500,
          }}
          className="mySwiper"
        >
          <SwiperSlide>
            <div class="container" onClick={handleClick}>
              <img src="/1.jpg" alt="Western" />

              <div class="center">
                <p
                  style={{
                    fontSize: 25,
                    color: "white ",
                    marginBottom: 0,
                    textAlign: "center",
                  }}
                >
                  WELCOME TO
                </p>
                <p
                  style={{
                    fontSize: 45,
                    marginTop: 0,
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  WEST{" "}
                  <span style={{ color: "rgb(5,160,254)", marginRight: 10 }}>
                    FLY
                  </span>
                  OVERSEAS
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundColor: "red" }}>
            <div class="container" onClick={handleClick}>
              <img src="/3.jpg" alt="West Fly Overseas" />
              <div class="center">
                <p
                  style={{
                    fontSize: 25,
                    color: "white ",
                    marginBottom: 0,
                    textAlign: "center",
                  }}
                >
                  THE BEST PTE INSTITUTE FOR
                </p>
                <p
                  style={{
                    fontSize: 45,
                    marginTop: 0,
                    color: "rgb(5,160,254)",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  STUDENTS
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div class="container" onClick={handleClick}>
              <img src="/3.jpg" alt="West Fly Overseas" />
              <div class="center">
                <p
                  style={{
                    fontSize: 25,
                    color: "white ",
                    marginBottom: 0,
                    textAlign: "center",
                  }}
                >
                  BETTER EDUCATION FOR A BETTER
                </p>
                <p
                  style={{
                    fontSize: 45,
                    marginTop: 0,
                    color: "rgb(5,160,254)",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  WORLD
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
          {country.map((e) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: 20,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Add this line for box shadow
                padding: "10px", // Add some padding for space around the content
                margin: "10px", // Add some margin for space between each container
              }}
            >
              <div>
                <img src={e.img} alt={e.name} />
              </div>
              <p>{e.name}</p>
            </div>
          ))}
        </div>

        <div
          className="mdashar"
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: 50,
            marginBottom: 50,
            justifyContent: "center",
          }}
        >
          <div class="responsive">
            <div class="gallery" onClick={() => navigate("/study-abroad")}>
              <img
                src="/Study-Abroad.jpg"
                alt="Forest"
                width="600"
                height="400"
                className="home-img"
              />

              <div style={{ color: "rgb(44,56,143)" }} class="desc">
                Study Abroad
              </div>
            </div>
          </div>
          <div class="responsive">
            <div class="gallery" onClick={() => navigate("/ielts")}>
              <img src="/IELTS.jpg" alt="Forest" width="600" height="400" />

              <div class="desc" style={{ color: "rgb(44,56,143)" }}>
                ILETS
              </div>
            </div>
          </div>
          <div class="responsive">
            <div class="gallery" onClick={() => navigate("/pte")}>
              <img
                src="/PTE.jpg"
                alt="Forest"
                width="600"
                height="400"
                className="home-img"
              />

              <div class="desc" style={{ color: "rgb(44,56,143)" }}>
                PTE
              </div>
            </div>
          </div>
          <div class="responsive">
            <div class="gallery" onClick={handleClick}>
              <img src="/se.jpg" alt="Forest" width="600" height="400" />

              <div style={{ color: "rgb(44,56,143)" }} class="desc">
                Spoken English
              </div>
            </div>
          </div>
        </div>
        <div
          className="mdashar"
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: 50,
            marginBottom: 50,
            justifyContent: "center",
          }}
        >
          <div class="responsive">
            <div class="gallery" onClick={handleClick}>
              <img
                src="/workvis.jpg"
                alt="Forest"
                width="600"
                height="400"
                className="home-img"
              />

              <div style={{ color: "rgb(44,56,143)" }} class="desc">
                Work Visa
              </div>
            </div>
          </div>
          <div class="responsive">
            <div class="gallery" onClick={handleClick}>
              <img
                src="/tourist.jpg"
                alt="Forest"
                width="600"
                height="400"
                className="home-img"
              />

              <div class="desc" style={{ color: "rgb(44,56,143)" }}>
                Tourist Visa
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

export const Footer = () => {
  return (
    <>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          borderTop: "2px rgb(5,160,254) solid",
          marginTop: 0,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          backgroundColor: "white",
        }}
      >
        <div
          style={{ maxWidth: "60%", minWidth: 500, color: "rgb(5,160,254)" }}
        >
          <p style={{ margin: 0 }}>
            Copyright © 2023{" "}
            <span style={{ fontWeight: "bolder" }}> West Fly Overseas </span>
            All Rights Reserved.
          </p>
        </div>
        <div style={{ paddingRight: 50 }}>
          <a
            style={{ color: "rgb(5,160,254)", margin: 10 }}
            href="https://www.facebook.com/profile.php?id=100095154944347&mibextid=LQQJ4d"
          >
            <BiLogoFacebook size={25} />
          </a>
          <a
            style={{ color: "rgb(5,160,254)", margin: 10 }}
            href="https://instagram.com/westflyoverseas?igshid=NzZlODBkYWE4Ng=="
          >
            <AiOutlineInstagram size={25} />
          </a>
        </div>
      </div>
    </>
  );
};
