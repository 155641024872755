import React from "react";
import "typeface-roboto";

export const About = () => {
  return (
    <>
      <div style={{ position: "relative", fontFamily: "Poppins, sans-serif" }}>
        <div>
          <img
            style={{
              width: "80%",
              height: 300,
              borderTop: "3px red",
              marginLeft: "10%",
            }}
            src="/1.jpg"
            alt="West Fly OverSeas"
          />
          <p
            style={{
              textAlign: "center",
              fontWeight: 600,
              fontSize: 40,
              marginTop: -170,
              color: "white",
              position: "absolute",
              bottom: 0,
              width: "80%",
              marginLeft: "10%",
              background: "rgba(0, 0, 0, 0.5)", // Added background color for better readability
            }}
          >
            ABOUT US
          </p>
        </div>
        {/* about us success */}
      </div>
      <div>
        <p
          style={{
            textAlign: "center",
            fontFamily: "Poppins, sans-serif",

            fontSize: 35,
          }}
          className="success"
        >
          OUR SUCCESS STATEMENTS
        </p>

        <p
          style={{
            marginLeft: "10%",
            textAlign: "center",
            marginRight: "10%",
            borderTop: "grey 1px solid",
            paddingTop: 30,
            fontFamily: "Poppins, sans-serif",
          }}
        >
          To impart excellent learning and counselling as well for ending
          barriers to latest information for students and other customers
          through personalized services and pioneering practices Forging
          high-brand equity in various functional areas of Overseas Immigration
          and Study visa Achieving maximum profitability along with safeguarding
          customer-satisfaction Striving restlessly towards excellence, always
        </p>
      </div>
      <div style={{ width: "100%" }}>
        <p
          style={{
            fontFamily: "Poppins, sans-serif",

            textAlign: "center",
            fontSize: 30,

            width: "50",
            marginLeft: "25%",
            marginRight: "25%",
            borderBottom: "red solid 1px",
          }}
        >
          THE BEST INSTITUTE FOR STUDENT VISA, IELTS, PTE & SPOKEN ENGLISH
        </p>
      </div>

      <div style={{}}>
        <div>
          <div
            className="insideHeader"
            style={{
              borderLeft: "red 2px solid",
              fontSize: 20,
              paddingLeft: 20,
              marginLeft: "25%",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            ABOUT OUR IELTS-PTE & SPOKEN ENGLISH COACHING
          </div>
          <div
            style={{
              marginTop: 20,
              fontFamily: "Poppins, sans-serif",
              width: "80%",
              marginLeft: "10%",
            }}
          >
            Also, we have our centers in different cities of Punjab and Haryana,
            to make you prepare for the language ability tests i.e. IELTS, PTE
            and Spoken English. Our IELTS, PTE & Spoken English trainers possess
            incomparable expertise and teaching skills. We have a team of IDP
            and British Council’s certified faculty. We have designed the
            curriculum and followed the teaching techniques that are suitable
            for every student and that are mandatory to achieve success in these
            language ability tests. We don’t force our services on students, but
            we first offer a free demo and give them an opportunity that they
            could never deny due to our talented staff. Also the facilities of
            our centers include the extra classes for grammar improvement,
            multimedia Classes and an up to date library of upgraded study
            material. Here, at Western Overseas Every module is practiced twice
            a day. On every weekend we organized the mock tests for our
            students, to analyze their practice, skills and to detect their
            problems in each module. Over the years, we are spreading our roots
            in Different cities. And just because of these efforts of our
            dedicated team members, now we are one of the top leading and
            winning education consultant in the North India. Simply we can say
            that join Western Overseas and took your one step towards the
            success.
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
