import React from "react";

export const Ielts = () => {
  const divStyle = {
    fontFamily: "Poppins, sans-serif",
  };
  let data = [
    {
      header: "What is the IELTS Exam?",
      text: "The International English Language Testing System (IELTS) is a globally recognised standardised test designed to assess the English language proficiency of individuals who wish to study, work, or migrate to English-speaking countries. The test evaluates four key language skills: Listening, Reading, Writing, and Speaking. The test is available in two main formats: Academic, for those seeking higher education opportunities, and General Training which focuses on practical language skills for work and migration purposes.",
    },
    {
      header: "Why is IELTS required?",
      text: "IELTS scores are used by universities, employers, immigration authorities, and other institutions to determine an individual's ability to effectively communicate in English. IELTS plays an important role in facilitating international mobility and communication across diverse contexts.",
    },
    {
      header: "Which IELTS test is right for you?",
      text: null,
      sub: [
        "There are two primary types of IELTS tests available: IELTS Academic and IELTS General Training.",
        "IELTS Academic evaluates your English language proficiency and determines its suitability for an academic setting. This test assesses your grasp of academic language and determines if you are adequately prepared to commence training or studying in English.",

        "IELTS General Training measures your English language skills in practical, everyday scenarios. This test includes both workplace and social situations, providing a comprehensive evaluation of your language abilities in real-life contexts.",
        ,
        "Choosing the right test depends on whether your objective is to pursue education or employment in your desired country.",
      ],
    },
    {
      header: "What is the format of the IELTS test?",
      text: "All IELTS tests include four sections: Listening, Reading, and Writing. However, the Speaking test may be scheduled within the week before or after the other sections.",
    },
    {
      header: "Countries where IELTS is accepted",
      text: "IELTS is used to test the English language proficiency of individuals who wish to study, work, or migrate in Canada, the USA, Australia, UK, New Zealand, and Ireland. Every country has set their own IELTS band score requirements. Let’s discuss the requirements of these countries in detail:",
    },
  ];
  let testData = [
    {
      a: "Listening	",
      b: "4 recorded monologues and conversations.",
      c: "Same",
      d: "40	",
      e: "30 minutes",
    },
    {
      a: "Reading	",
      b: "3 long reading passages with tasks covering descriptive, factual, and analytical concepts.",
      c: "Different	Different	",
      d: "40	",
      e: "60 minutes",
    },
    {
      a: "Writing	",
      b: "2 short essays each 150-250 words. The questions will be in the form of a graph, chart, or diagram, and the candidate will interpret and describe the data.	",
      c: "Different",
      d: "2",
      e: "60 minutes",
    },
    {
      a: "Speaking",
      b: "Face-to-face interview with a few short questions, a brief conversation on a familiar topic, and a structured discussion.",
      c: "Same",
      d: "3-5",
      e: "11-14 minutes",
    },
  ];
  return (
    <div style={{ maxWidth: "80%", margin: "auto", ...divStyle }}>
      <img src={"./d.png"} style={{ height: 300, width: "100%" }} />
      {data.map((e) => (
        <>
          <p style={{ fontWeight: "bold" }}>{e.header}</p>
          {e?.sub?.map((e) => (
            <Text text={e}></Text>
          ))}
          {e.text && <Text text={e?.text}></Text>}
        </>
      ))}
      <div style={{ display: "flex", flexDirection: "row" }}>
        <p
          style={{
            width: "10%",

            textAlign: "center",
            backgroundColor: "rgb(138,188,196)",
            color: "white",
          }}
        >
          Sections
        </p>
        <p
          style={{
            width: "40%",
            padding: "10px",
            textAlign: "center",
            backgroundColor: "rgb(124,156,171)",
            color: "white",
          }}
        >
          Pattern of IELTS exam{" "}
        </p>
        <p
          style={{
            width: "25%",

            textAlign: "center",
            backgroundColor: "rgb(107,134,149)",
            color: "white",
          }}
        >
          Similarity between IELTS Academic and IELTS General Training version{" "}
        </p>
        <p
          style={{
            width: "10%",

            textAlign: "center",
            backgroundColor: "rgb(76,108,123)",
            color: "white",
          }}
        >
          Total questions{" "}
        </p>
        <p
          style={{
            width: "10%",

            textAlign: "center",
            backgroundColor: "rgb(106,134,155)",
            color: "white",
          }}
        >
          Duration
        </p>
      </div>
      {testData.map((e) => (
        <RenderTable data={e} />
      ))}
      <p>
        Pattern of IELTS exam check how the candidates are focused on the
        details, and their ability to fetch details as well as their
        understanding of visual details, key information, and concrete facts,
        For IELTS academic and general training, there is no difference for this
        section.
      </p>
    </div>
  );
};

const Text = ({ text }) => {
  return <p>{text}</p>;
};
const RenderTable = ({ data }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        <p
          style={{
            width: "10%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "rgb(138,188,196)",
            color: "white",
          }}
        >
          {data.a}
        </p>
        <p
          style={{
            width: "40%",

            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

            color: "black",
          }}
        >
          {data.b}
        </p>
        <p
          style={{
            width: "25%",

            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "black",
          }}
        >
          {data.c}
        </p>
        <p
          style={{
            width: "10%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",

            color: "black",
          }}
        >
          {data.d}
        </p>
        <p
          style={{
            width: "10%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",

            color: "black",
          }}
        >
          {data.e}
        </p>
      </div>
    </>
  );
};
