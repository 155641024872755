import React, { useState } from "react";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { ImLocation } from "react-icons/im";
import { MdOutlineFlightTakeoff } from "react-icons/md";
import axios from "axios";
import { Button } from "@mui/material";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission logic here
    let str = "";
    for (let x in formData) {
      str += `${x.toUpperCase()} : ${formData[x]} \n`;
    }

    const response = await axios.post(
      "https://discord.com/api/webhooks/1168913565034491934/_fx0va-hLPkgcF5LtBmkoRFdSO65W08FLSuSCNcHSX7woRq6yBDUr5E-WlaeiodZpnFT",

      {
        content: str,
      }
    );
    console.log(response);
  };

  return (
    <div
      style={{
        marginTop: "50%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "90%",
        margin: "auto",
      }}
    >
      <div style={{ paddingTop: 50, width: "40%", marginRight: "10%" }}>
        <div
          style={{
            padding: "3%",
            borderRadius: 20,
            boxShadow: "1px 2px 9px rgb(5,160,254)",
          }}
        >
          <p style={{ fontSize: 20, fontWeight: 500, color: "rgb(44,56,143)" }}>
            CONTACT INFO
          </p>
          <p style={{ borderBottom: "1px solid  rgb(5,160,254)" }}>
            Welcome to our Website. We are glad to have you around.
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsTelephoneFill
              size={20}
              color="rgb(5,160,254)"
              style={{ marginRight: 10 }}
            />{" "}
            +91-88180-20289
          </p>

          <p
            style={{
              paddingBottom: 5,

              display: "flex",
              alignItems: "center",
            }}
          >
            <MdEmail
              size={20}
              color="rgb(5,160,254)"
              style={{ marginRight: 10 }}
            />{" "}
            westflyoverseas@gmail.com
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 0,
            }}
          >
            <ImLocation
              size={20}
              color="rgb(5,160,254)"
              style={{ marginRight: 10 }}
            />
            Address
          </p>
          <div style={{ marginLeft: "10%" }}>
            <p style={{ fontSize: 20, marginTop: 0, marginBottom: 0 }}>
              Bilaspur 135102
            </p>
            <p style={{ marginBottom: 0, marginTop: 0 }}>Opp. Grain Market </p>
            <p style={{ marginBottom: 0, marginTop: 0 }}>Yamuna Nagar</p>
            <p style={{ marginBottom: 0, marginTop: 0 }}>Haryana ,INDIA</p>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: 50 }}>
        <div
          class="contact1_form"
          style={{
            boxShadow: "1px 2px 9px rgb(5,160,254)",
            padding: "3%",
            borderRadius: 20,
          }}
        >
          <h3 style={{ color: "rgb(44,56,143)" }}>SEND A MESSAGE</h3>
          <p class="addres_p">
            Your email address will not be published. Required fields are
            marked.
          </p>
          <div
            style={{
              justifyContent: "left",
              alignItems: "flex-start",
              width: 410,
            }}
          >
            <input
              style={{ height: 30, width: 200 }}
              type="text"
              className="name"
              placeholder="Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
            <input
              style={{ height: 30, width: 200, marginLeft: 10 }}
              type="text"
              className="email"
              placeholder="Email Address"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <input
              style={{ height: 30, width: 410 }}
              type="number"
              className="phone"
              placeholder="Phone Number"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <input
              style={{ height: 30, width: 410 }}
              type="text"
              className="subject"
              placeholder="Subject"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
            />
          </div>
          <div class="contint_sub">
            <textarea
              class="cont_input4"
              name="message"
              id="message"
              placeholder="Message *"
              rows="4"
              cols="54"
              required=""
              value={formData.message}
              onChange={handleInputChange}
            ></textarea>
          </div>
          <Button
            style={{
              backgroundColor: "white",
              color: "rgb(5,160,254)",
              border: "2px solid rgb(5,160,254)",
              marginTop: 10,
            }}
            onClick={handleSubmit}
          >
            Send Message
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
