import React from "react";

export const Abroad = () => {
  let data = [
    {
      a: "Study in Australia",
      img: "/australia.png",
      b: [
        "Variety of courses",
        "Multiple scholarship programs",
        "World-class education",
        "Affordable living",
        "Technologically Advanced courses",
      ],
    },
    {
      a: "Study in the UK",
      img: "/united_kingdom.png",
      b: [
        "Globally accepted degrees",
        "Tensile education system",
        " Study and earn simultaneously",
        "Flexible scholarship programs",
        "Amazing campus life",
      ],
    },
    {
      a: "Study in the US",
      img: "/united_states.png",
      b: [
        "Globally accepted degrees",
        "Tensile education system",
        "Study and earn simultaneously",
        "Flexible scholarship programs",
        "Amazing campus life",
      ],
    },
    {
      a: "Study in the CANADA",
      img: "/canada.png",
      b: [
        "Scholastic excellence",
        "Affordable fees",
        "Focussed approach on skill development",
        "6th Safest country to live in according to Global Peace Index 2022",
        "Diversified culture",
        "Post-study career opportunities",
      ],
    },
    {
      a: "Study in New Zealand",
      img: "./nz.png",
      b: [
        "Affordable tuition fee",
        "Great scholarships programs",
        "Good scope for Ph.D. scholars",
        "Emphasis on innovation and research",
        "2nd Safest country to live in according to Global Peace Index 2022",
        "Accredited qualifications across the globe",
      ],
    },
  ];
  return (
    <>
      <img
        style={{ height: 300, width: "80%", marginLeft: "10%" }}
        src="/Study-Visa.jpg"
        alt="Study Abroad"
      />
      <p style={{ fontSize: 40, fontStyle: "italic", textAlign: "center" }}>
        Study abroad in popular international destinations
      </p>
      <div
        style={{
          width: "90%",
          marginLeft: "5%",
          margin: "auto",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        {data.map((e) => (
          <CountryRender data={e} key={e.a} />
        ))}
      </div>
    </>
  );
};
const CountryRender = ({ data }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: 400,

        justifyContent: "space-evenly",
        alignItems: "center",

        minWidth: 400,
      }}
    >
      <div>
        <img style={{ width: 150, height: 130 }} src={data.img} />
      </div>
      <div>
        <p style={{ fontSize: 20, fontStyle: "italic", fontWeight: "bold" }}>
          {data.a}
        </p>
        <ul>
          {data.b.map((e) => (
            <li key={e}>{e}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};
