import React from "react";
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import Home from "./Home";
import { Footer } from "./Home";

import About from "./About";
import PTE from "./PteAca";
import Contact from "./Contact";
import { Ielts } from "./Iltes";
import { Abroad } from "./studyAbroad";
import "./App.css";
const App = () => {
  const navLinkStyle = {
    color: "rgb(5,160,254)",
    textDecoration: "none",
    fontWeight: "bold",
    margin: "0 10px",
  };

  const activeLinkStyle = {
    color: "red",
    fontWeight: "1000",

    backgroundColor: "grey", // Change this to your desired active link color
  };

  return (
    <BrowserRouter>
      <div>
        <div className="main">
          <img
            style={{ width: 100, height: 40, marginLeft: 10 }}
            src="/west fly logo.jpg"
            alt="My Image"
          />
          <nav
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              width: "50%",
            }}
          >
            <NavLink to="/" style={navLinkStyle} activeStyle={activeLinkStyle}>
              HOME
            </NavLink>
            <NavLink
              to="/ielts"
              style={navLinkStyle}
              activeStyle={{
                backgroundColor: "rgb(5,160,254)",
                color: "white",
              }}
            >
              IELTS
            </NavLink>
            <NavLink
              to="/pte"
              style={navLinkStyle}
              activeStyle={activeLinkStyle}
            >
              PTE
            </NavLink>
            <NavLink
              to="/study-abroad"
              style={navLinkStyle}
              activeStyle={activeLinkStyle}
            >
              STUDY ABROAD
            </NavLink>
            <NavLink
              to="/about"
              style={navLinkStyle}
              activeStyle={activeLinkStyle}
            >
              ABOUT
            </NavLink>
            <NavLink
              to="/contact"
              style={navLinkStyle}
              activeStyle={activeLinkStyle}
            >
              CONTACT US
            </NavLink>
          </nav>
        </div>

        <div className="mainContent">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/pte" element={<PTE />} />
            <Route path="/ielts" element={<Ielts />} />
            <Route path="/study-abroad" element={<Abroad />} />
          </Routes>
          <div style={{ paddingTop: 50 }}>
            <Footer />
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
