import React from "react";

const PTE = () => {
  return (
    <>
      <div style={{ position: "relative" }}>
        <div>
          <img
            style={{
              width: "80%",
              height: 250,
              borderTop: "3px red",
              marginLeft: "10%",
            }}
            src="/best_h.jpg"
            alt="Western"
          />
          <p
            style={{
              textAlign: "center",
              fontWeight: 600,
              fontSize: 40,
              marginTop: -170,
              color: "white",
              position: "absolute",
              bottom: 0,
              width: "80%",
              marginLeft: "10%",
              background: "rgba(0, 0, 0, 0.5)", // Added background color for better readability
            }}
          >
            ABOUT THE TEST
          </p>
        </div>
      </div>
      <div style={{ width: "80%", margin: "auto" }}>
        <p>
          To complete a PTE test, you will need to attend a secure Pearson test
          centre. You will use a computer and headset to listen to, read and
          respond to questions.
        </p>
        <p>
          During the three-hour test session, there are three main parts to the
          test: speaking and writing (together), listening and reading. There is
          also an optional 10-minute break between the reading and listening
          parts. During the test, you will find twenty different question
          formats, ranging from multiple choice through to essay writing and
          interpreting information.
        </p>
        <p>
          PTE Academic assesses real-life, academic English, so you will hear
          excerpts from lectures and view graphs and charts. You will hear a
          range of accents in the test, from British and American to non-native
          speakers, so you will be exposed to the type of accents you will
          encounter in everyday life.
        </p>
      </div>
      <div style={{ width: "80%", margin: "auto" }}>
        <p style={{ fontSize: 20 }}>
          Part 1: Speaking & Writing (77 – 93 Minutes)
        </p>
        <ul>
          <li>Personal Introduction</li>
          <li>Read Aloud</li>
          <li>Repeat Sentence</li>
          <li>Describe Image</li>
          <li>Re-tell Lecture</li>
          <li>Answer Short Question</li>
          <li>Summarize Written Text</li>
          <li>Essay</li>
        </ul>
        <p>
          In Part 1 you will be tested on your speaking and writing skills. The
          time given to this section is 77-93 minutes.
        </p>
        <p>
          To assess speaking, your skills will be tested by your ability to
          produce
        </p>
        <p>
          The writing section will require you to write responses in academic
          English using correct grammar and spelling.
        </p>
        <p style={{ fontSize: 20 }}>Part 2: Reading (32 – 41 Minutes)</p>
        <ul>
          <li>Multiple choice, choose single answer</li>
          <li>Multiple choice, choose multiple answers</li>
          <li>Re-order paragraphs</li>
          <li>Reading: Fill in the blanks</li>
          <li>Reading &amp; writing: Fill in the blanks</li>
        </ul>
        <p>
          In Part 2 you will be tested on your reading skills. This single timed
          section contains 15-20 independent and integrated skills items,
          depending on the combination of items in a given test.
        </p>
        <p>
          Different response formats, including multiple-choice, re-order
          paragraphs, and fill in the blanks, are designed to test your ability
          to read, write and listen to information provided in English.
        </p>
        <p>
          Because PTE Academic is an integrated skill test, one item type in
          this part of the test also assesses writing skills.
        </p>
        <p style={{ fontSize: 20 }}>Part 3: Listening (45 – 57 Minutes)</p>
        <ul>
          <li>Summarize spoken text</li>
          <li>Multiple choice, choose multiple answer</li>
          <li>Fill in the blanks</li>
          <li>Highlight correct summary</li>
          <li>Multiple choice, choose single answer</li>
          <li>Select missing word</li>
          <li>Highlight incorrect words</li>
          <li>Write from dictation</li>
        </ul>
        <p>
          Part 3 consists of questions that are based on audio or video clips
          which begin to play automatically. You hear each audio or video clip
          only once so listen carefully. You are allowed to take notes.
        </p>
        <p>
          For each question you have the opportunity to adjust the volume. While
          the audio clip is playing, move the control bar to the right to
          increase the volume or to the left to decrease the volume.
        </p>
      </div>
    </>
  );
};

export default PTE;
